import React, { useState } from "react";
import { Title } from "../../../common/";
import { Line } from "react-chartjs-2";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

interface LineChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
  }[];
}

interface LineChartProps {
  title: string;
  timeframe: string;
  lineData: (timeframe: string, dateInfo: DateInfo) => LineChartData;
  setTimeframe: (timeframe: string) => void;
  options: Record<string, unknown>;
  setSelectedDate: (date: string) => void;
}

interface DateInfo {
  year: number;
  month?: number;
  day?: number;
  weekStartDate?: string;
  weekEndDate?: string;
}

const A_LineChartSection: React.FC<LineChartProps> = ({
  title,
  timeframe,
  lineData,
  setTimeframe,
  options,
  setSelectedDate,
}) => {
  const timeframes = ["12 months", "30 days", "7 days", "24 hours"];
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [startYear, setStartYear] = useState(new Date().getFullYear() - 10);
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const selectYear = (year: number) => {
    setShowYearPicker(false);
    setDateInfo((prev) => ({ ...prev, year }));
    setSelectedDate(formatDisplayDate({ ...dateInfo, year }));
  };

  const getDaysInMonth = (year: number, month: number): number[] => {
    const daysInMonth = new Date(year, month, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  const [dateInfo, setDateInfo] = useState<DateInfo>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
    weekStartDate: getStartOfWeek(new Date()),
    weekEndDate: getEndOfWeek(new Date()),
  });

  function getStartOfWeek(date: Date): string {
    const start = new Date(date);
    const day = start.getDay();
    const diff = start.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    start.setDate(diff);
    return `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, "0")}-${String(start.getDate()).padStart(2, "0")}`;
  }

  function getEndOfWeek(date: Date): string {
    const end = new Date(date);
    const day = end.getDay();
    const diff = end.getDate() - day + 7; // Ensure Sunday is the end
    end.setDate(diff);
    return `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, "0")}-${String(end.getDate()).padStart(2, "0")}`;
  }

  const adjustDate = (field: keyof DateInfo, direction: "prev" | "next") => {
    setDateInfo((prevDateInfo) => {
      const newDateInfo = { ...prevDateInfo };

      if (field === "year") {
        newDateInfo.year += direction === "prev" ? -1 : 1;
      } else if (field === "month") {
        // Adjust the month
        newDateInfo.month =
          direction === "prev"
            ? newDateInfo.month! - 1
            : newDateInfo.month! + 1;

        // Handle year overflow when month < 1 or > 12
        if (newDateInfo.month! < 1) {
          newDateInfo.month = 12;
          newDateInfo.year -= 1;
        } else if (newDateInfo.month! > 12) {
          newDateInfo.month = 1;
          newDateInfo.year += 1;
        }

        // Calculate the last day of the new month
        const lastDayOfMonth = new Date(
          newDateInfo.year,
          newDateInfo.month!,
          0
        ).getDate();

        // Set the day to the last day of the month
        newDateInfo.day = lastDayOfMonth;
      } else if (field === "day") {
        const currentDate = new Date(
          newDateInfo.year,
          newDateInfo.month! - 1,
          newDateInfo.day!
        );
        const newDate = new Date(
          currentDate.setDate(
            currentDate.getDate() + (direction === "prev" ? -1 : 1)
          )
        );
        newDateInfo.year = newDate.getFullYear();
        newDateInfo.month = newDate.getMonth() + 1;
        newDateInfo.day = newDate.getDate();
      } else if (field === "weekStartDate") {
        const currentStart = new Date(prevDateInfo.weekStartDate!);
        const adjustDays = direction === "prev" ? -7 : 7;
        const newStart = new Date(
          currentStart.setDate(currentStart.getDate() + adjustDays)
        );
        newDateInfo.weekStartDate = getStartOfWeek(newStart);
        newDateInfo.weekEndDate = getEndOfWeek(newStart);
      }

      // Ensure `selectedDate` updates after recalculating `day`
      const formattedDate = formatDisplayDate(newDateInfo);
      if (formattedDate) {
        setSelectedDate(formattedDate);
      }

      return newDateInfo; // Return the updated state
    });
  };

  React.useEffect(() => {
    const currentDate = new Date();
    const updatedDateInfo = { ...dateInfo };

    if (timeframe === "12 months") {
      updatedDateInfo.year = currentDate.getFullYear();
      updatedDateInfo.month = undefined;
      updatedDateInfo.day = undefined;
    } else if (timeframe === "30 days") {
      updatedDateInfo.year = currentDate.getFullYear();
      updatedDateInfo.month = currentDate.getMonth() + 1;
      updatedDateInfo.day = new Date(
        updatedDateInfo.year,
        updatedDateInfo.month,
        0
      ).getDate(); // Last day of the current month
    } else if (timeframe === "7 days") {
      updatedDateInfo.weekStartDate = getStartOfWeek(currentDate);
      updatedDateInfo.weekEndDate = getEndOfWeek(currentDate);
    } else if (timeframe === "24 hours") {
      updatedDateInfo.year = currentDate.getFullYear();
      updatedDateInfo.month = currentDate.getMonth() + 1;
      updatedDateInfo.day = currentDate.getDate();
    }

    setDateInfo(updatedDateInfo);
    setSelectedDate(formatDisplayDate(updatedDateInfo));
  }, [timeframe]);

  const formatDisplayDate = (info: DateInfo = dateInfo): string => {
    if (timeframe === "12 months") {
      return `${info.year}-12-31`;
    } else if (timeframe === "30 days") {
      // Use the last day of the current month
      return `${info.year}-${String(info.month).padStart(2, "0")}-${String(
        info.day
      ).padStart(2, "0")}`;
    } else if (timeframe === "7 days") {
      // Use the end of the week
      return info.weekEndDate || "";
    } else if (timeframe === "24 hours") {
      // Use the specific day
      return `${info.year}-${String(info.month).padStart(2, "0")}-${String(
        info.day
      ).padStart(2, "0")}`;
    }
    return ""; // Fallback for unexpected timeframe
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="p-5 rounded-md bg-primaryColor-50 mt-7 mb-7">
      <div className="mt-6 text-center">
        <Title
          title={title}
          textColor="text-black"
          fontWeight="font-semibold"
          bgStyle={false}
        />
      </div>
      <div className="relative flex justify-center gap-4 mb-4 top-3">
        {timeframes.map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => setTimeframe(option)}
            className={`px-2 md:px-4 py-2 rounded text-xs md:text-base drop-shadow-lg ${
              timeframe === option
                ? "bg-[#ffffff] text-primaryColor-600"
                : "bg-gray-200"
            }`}
          >
            {option}
          </button>
        ))}
      </div>

      <div className="flex flex-wrap items-center justify-center gap-5 mt-10 md:gap-10">
        {/* Year Controls */}
        {timeframe !== "7 days" && (
          <div className="relative flex items-center gap-2">
            <button
              type="button"
              onClick={() => adjustDate("year", "prev")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronLeft size={20} />
            </button>
            <span
              onClick={() => {
                setShowYearPicker(!showYearPicker);
                setShowMonthPicker(false);
              }}
              className="text-lg font-semibold cursor-pointer"
            >
              {dateInfo.year}
            </span>
            <button
              type="button"
              onClick={() => adjustDate("year", "next")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronRight size={20} />
            </button>
          </div>
        )}

        {/* Month Controls */}
        {timeframe !== "12 months" && timeframe !== "7 days" && (
          <div className="relative flex items-center gap-2">
            <button
              type="button"
              onClick={() => adjustDate("month", "prev")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronLeft size={20} />
            </button>
            <span
              onClick={() => {
                setShowMonthPicker(!showMonthPicker);
                setShowYearPicker(false);
              }}
              className="text-lg font-semibold cursor-pointer"
            >
              {String(dateInfo.month).padStart(2, "0")}
            </span>
            <button
              type="button"
              onClick={() => adjustDate("month", "next")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronRight size={20} />
            </button>
          </div>
        )}

        {/* Day Controls */}
        {timeframe === "7 days" && (
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => adjustDate("weekStartDate", "prev")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronLeft size={20} />
            </button>
            <span
              onClick={() => setShowDatePicker(true)}
              className="text-lg font-semibold cursor-pointer "
            >
              {timeframe === "7 days"
                ? `${dateInfo.weekStartDate} to ${dateInfo.weekEndDate}`
                : String(dateInfo.day).padStart(2, "0")}
            </span>
            <button
              type="button"
              onClick={() => adjustDate("weekStartDate", "next")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronRight size={20} />
            </button>
          </div>
        )}

        {/* Day Controls */}
        {timeframe === "24 hours" && (
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => adjustDate("day", "prev")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300 "
            >
              <FiChevronLeft size={20} />
            </button>
            <span
              onClick={() => setShowDayPicker(!showDayPicker)}
              className="text-lg font-semibold cursor-pointer"
            >
              {String(dateInfo.day).padStart(2, "0")}
            </span>
            <button
              type="button"
              onClick={() => adjustDate("day", "next")}
              className="p-2 bg-gray-200 rounded-full active:bg-gray-300"
            >
              <FiChevronRight size={20} />
            </button>
          </div>
        )}
      </div>

      <div className="mt-8">
        <Line
          data={lineData(timeframe, dateInfo)}
          height={400}
          options={options}
        />
      </div>

      {/* Dynamic Year Picker */}
      <Modal
        visible={showYearPicker}
        onCancel={() => setShowYearPicker(false)}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
        className="custom-modal2"
      >
        <div className="justify-center p-2 overflow-y-auto top-10">
          {/* Navigation Arrows */}
          <div className="flex items-center justify-between mb-2">
            <button
              type="button"
              onClick={() => setStartYear((prev) => prev - 20)}
              className="p-2 text-gray-700 rounded-full hover:bg-gray-100"
            >
              <FiChevronLeft size={20} />
            </button>
            <span className="font-semibold text-gray-600 ">
              {startYear} - {startYear + 19}
            </span>
            <button
              type="button"
              onClick={() => setStartYear((prev) => prev + 20)}
              className="p-2 text-gray-700 rounded-full hover:bg-gray-100"
            >
              <FiChevronRight size={20} />
            </button>
          </div>

          {/* Year List */}
          <div className="flex flex-wrap justify-center ">
            {Array.from({ length: 20 }, (_, i) => startYear + i).map((year) => (
              <div
                key={year}
                className="px-4 py-1 text-center cursor-pointer hover:bg-gray-100"
                onClick={() => selectYear(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* Dynamic month Picker */}
      <Modal
        visible={showMonthPicker}
        onCancel={() => setShowMonthPicker(false)}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
        className="custom-modal2"
      >
        <div className="justify-center p-2 top-10">
          {/* Month List */}
          <div className="flex flex-wrap justify-center gap-2">
            {monthNames.map((month, index) => (
              <div
                key={index}
                className="px-4 py-1 text-center cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  const daysInMonth = getDaysInMonth(dateInfo.year, index + 1);
                  const lastDayOfMonth = daysInMonth.slice(-1)[0];
                  setShowMonthPicker(false);
                  setDateInfo((prev) => ({
                    ...prev,
                    month: index + 1,
                    day: lastDayOfMonth,
                  }));
                  setSelectedDate(
                    formatDisplayDate({
                      ...dateInfo,
                      month: index + 1,
                      day: lastDayOfMonth,
                    })
                  );
                }}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* Dynamic Day Picker */}
      <Modal
        visible={showDayPicker}
        onCancel={() => setShowDayPicker(false)}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
        className="custom-modal2"
      >
        <div className="justify-center p-2 top-10">
          {/* Day List */}
          <div className="flex flex-wrap justify-center gap-2">
            {getDaysInMonth(dateInfo.year, dateInfo.month!).map((day) => (
              <div
                key={day}
                className="px-4 py-1 text-center cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  setShowDayPicker(false);
                  setDateInfo((prev) => ({ ...prev, day }));
                  setSelectedDate(formatDisplayDate({ ...dateInfo, day }));
                }}
              >
                {day}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* 7 days date picker  */}
      {/* 7 days date picker */}
      <Modal
        visible={showDatePicker}
        onCancel={() => setShowDatePicker(false)}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
        className="custom-modal3"
      >
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              shouldDisableDate={(date) => {
                // Allow only Mondays
                return date.day() !== 1; // `day() === 1` means Monday
              }}
              onChange={(selectedDate) => {
                if (selectedDate) {
                  // Ensure selectedDate is a valid date
                  const startDate = selectedDate.format("YYYY-MM-DD"); // Selected Monday
                  const endDate = selectedDate
                    .add(6, "day")
                    .format("YYYY-MM-DD"); // Following Sunday

                  setDateInfo((prev) => ({
                    ...prev,
                    weekStartDate: startDate,
                    weekEndDate: endDate,
                  }));
                  setSelectedDate(endDate); // Optionally update selectedDate if needed
                  setShowDatePicker(false); // Close the modal
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </Modal>
    </div>
  );
};

export default A_LineChartSection;
