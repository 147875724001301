import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { getAllSchedulesByCusNIC } from "../../api/schedule";
import { Button, Description, Logo } from "../common";
import { formatDate } from "../../utils/formatters";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import SinhalaFont from "../../fonts/SinhalaFont";

// Extend the jsPDF interface to include autoTable
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

jsPDF.API.events.push([
  "addFonts",
  function (this: typeof jsPDF.prototype) {
    this.addFileToVFS("SinhalaFont.ttf", SinhalaFont);
    this.addFont("SinhalaFont.ttf", "SinhalaFont", "normal");
  },
]);

interface Installments {
  currentInstallmentAmount: number;
  dueDate: string;
  latePaymentInterest: number;
  loanAmount: number;
  loanStartDate: string;
  endDate: string;
  paymentStatus: string;
  totalAmountDue: number;
  scheduleId: number;
  totalLoanAmount: number;
  pureRemainingLoanAmount: number;
  remainingAmount: number;
  paidAmount: number;
  loanStatus: string;
  fullPaymentAmount: number;
  balanceCarriedForward: number;
  paymentDate: string;
  paidTime: string;
  payments: {
    paymentId: number;
    dueAmount: number;
    paidAmount: number;
    balanceAmount: number;
    paymentMethod: string;
    paymentDateAndTime: string;
    paymentDate: string;
    signatureImageURL: string;
    paymentType: string;
    paymentStatus: string;
  }[];
}

interface BlacklistOverviewProps {
  visible: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  customerNic: string;
  customerName?: string;
  customerAddress?: string;
  loanID?: number;
}

const ScheduleListModal: React.FC<BlacklistOverviewProps> = ({
  visible,
  onClose,
  closeIcon,
  customerNic,
  customerName,
  customerAddress,
  loanID,
}) => {
  const [getInstallments, setGetInstallments] = useState<Installments[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getInstallmentsByCusNIC = async () => {
      const token = localStorage.getItem("token") || "";
      const nic = customerNic || "";
      const loanId = loanID || 0;

      try {
        const response = await getAllSchedulesByCusNIC(token, nic, loanId);
        const sortedData = response.data.sort(
          (a: Installments, b: Installments) => a.scheduleId - b.scheduleId
        );
        setGetInstallments(sortedData);
      } catch (error) {
        console.error(error);
      }
    };
    if (customerNic) {
      getInstallmentsByCusNIC();
    }
  }, [customerNic]);

  const handleDownloadPDF = () => {
    setIsLoading(true); // Set loading state to true

    const content = document.getElementById("pdf-header-content");

    if (content) {
      html2canvas(content, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          // Register and use the Sinhala font
          pdf.setFont("SinhalaFont");

          const imgWidth = 190;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Add the image (HTML content) to the PDF
          pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

          // Prepare table data
          const tableData = getInstallments.map((installment, index) => {
            const isLastSchedule =
              index === getInstallments.length - 1 &&
              installment.paymentStatus === "PAID";

            return [
              // වාරිකය
              index + 1,
              // "වාරික මුදල (රු.)",
              installment.currentInstallmentAmount.toFixed(2),
              // "ගෙවිය යුතු දිනය",
              formatDate(installment.dueDate),
              // "ගෙවන ලද දිනය"
              installment.payments && installment.payments.length > 0
                ? installment.payments
                    .map((payment) => formatDate(payment.paymentDate))
                    .join(",\n ") // Join payment dates as a comma-separated string
                : installment.paymentStatus === "PAID" &&
                    installment.paymentDate === null
                  ? "-"
                  : formatDate(installment.paymentDate),
              // වාරිකය සම්පුර්‍ර්ණැ කල දිනය
              formatDate(installment.paidTime),
              // "ගෙවූ මුදල (රු.)"
              installment.payments && installment.payments.length > 0
                ? installment.payments
                    .map((payment) =>
                      payment.paidAmount === 0 &&
                      payment.paymentStatus === "PAID"
                        ? "-"
                        : payment.paidAmount === 0
                          ? ""
                          : `${payment.paidAmount.toFixed(2)}`
                    )
                    .join(",\n ")
                : "",
              // "සම්පූර්ණ වූ ඉදිරි වාරික (රු.)",
              isLastSchedule
                ? "0.00"
                : installment.paidAmount === 0
                  ? ""
                  : installment.paidAmount.toFixed(2),
              // "ඉදිරියට ගෙනගිය ශේෂය (රු.)",
              (() => {
                if (isLastSchedule) {
                  return "0.00";
                }
                const amount = installment.balanceCarriedForward;
                return amount === 0 && installment.paymentStatus === "PAID"
                  ? "-"
                  : installment.fullPaymentAmount === 0 &&
                      installment.paymentStatus !== "PAID"
                    ? ""
                    : amount !== 0
                      ? amount.toFixed(2)
                      : "";
              })(),
              // "පොලී රහිත ඉතිරි ණය මුදල (රු.)",
              isLastSchedule
                ? "0.00"
                : installment.pureRemainingLoanAmount === 0
                  ? ""
                  : installment.pureRemainingLoanAmount.toFixed(2),
              // "පොලී සහිත ඉතිරි ණය මුදල (රු.)",
              isLastSchedule
                ? "0.00"
                : installment.remainingAmount === 0
                  ? ""
                  : installment.remainingAmount.toFixed(2),
              // "ප්‍රමාද වූ ගෙවීම් පොලී (රු.)",
              installment.latePaymentInterest === 0
                ? installment.paymentStatus === "PAID"
                  ? "-"
                  : ""
                : installment.latePaymentInterest.toFixed(2),
              // "ගෙවීම් තත්වය",
              installment.paymentStatus === "PAID" ? "Paid" : " ",
            ];
          });

          // Generate the table after the image
          pdf.autoTable({
            head: [
              [
                "වාරිකය",
                "වාරික මුදල (රු.)",
                "ගෙවිය යුතු දිනය",
                "ගෙවන ලද දිනය",
                "වාරිකය සම්පූර්ණ කළ දිනය",
                "ගෙවූ මුදල (රු.)",
                "සම්පූර්ණ වූ ඉදිරි වාරික (රු.)",
                "ඉදිරියට ගෙනගිය ශේෂය (රු.)",
                "පොලී රහිත ඉතිරි ණය මුදල (රු.)",
                "පොලී සහිත ඉතිරි ණය මුදල (රු.)",
                "ප්‍රමාද වූ ගෙවීම් පොලී (රු.)",
                "ගෙවීම් තත්වය",
              ],
            ],
            body: tableData,
            startY: imgHeight + 20,
            theme: "striped",
            headStyles: {
              fillColor: [142, 187, 177],
              textColor: [255, 255, 255],
              fontStyle: "bold",
              halign: "center",
              font: "SinhalaFont", // Use the custom font
            },
            styles: {
              fontSize: 8,
              font: "SinhalaFont", // Use the custom font
            },
            bodyStyles: {
              textColor: [0, 0, 0],
              halign: "center",
              font: "SinhalaFont", // Use the custom font
            },
            columnStyles: {
              0: { cellWidth: 15 },
              3: { cellWidth: 20 },
              6: { halign: "center" },
            },
          });

          pdf.save(`${customerNic}_all-schedules.pdf`);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  // Add this before the return statement
  const loanAmount =
    getInstallments.length > 0 ? getInstallments[0].loanAmount : 0;
  // Add this before the return statement
  const totalLoanAmount =
    getInstallments.length > 0 ? getInstallments[0].totalLoanAmount : 0;

  // Extract and format the loan start date
  const loanStartDate =
    getInstallments.length > 0
      ? formatDate(getInstallments[0].loanStartDate)
      : "";

  // Extract and format the loan start date
  const loanEndDate =
    getInstallments.length > 0 ? formatDate(getInstallments[0].endDate) : "";

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="p-5 font-semibold custom-modal"
      width="max-w-xl"
    >
      <div className="flex justify-center">
        <Button
          text={isLoading ? "Downloading..." : "Download as PDF"}
          onClick={handleDownloadPDF}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>

      <div id="pdf-header-content" className="p-5 ">
        {/* Our Details */}

        <div className="-mb-2 -ml-2">
          <Logo width="w-52" />
        </div>
        <div className="flex justify-between">
          <div className="mb-5 text-center">
            <Description
              content="26, ඉසුරු නිවස, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
            />
            <Description
              content="කෝන්කැටියාර සූරියවැව"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
            />
            <Description
              content="info@credimanage.com"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-600"}
            />
          </div>

          {/* customer details  */}
          <div>
            <Description
              content={customerName || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
              right
            />
            <Description
              content={customerNic || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
              right
            />
            <Description
              content={customerAddress || ""}
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-600"}
              right
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-between gap-3 p-2 border rounded-lg border-primaryColor-200">
          {/* ණය මුදල  */}
          <div>
            <Description
              content="ඉල්ලුම් කළ ණය මුදල"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor="text-gray-600"
              center
            />
            <Description
              content={`Rs.${loanAmount.toFixed(2)} `}
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
          </div>
          {/* ගෙවිය යුතු ණය මුදල  */}
          <div>
            <Description
              content="ගෙවිය යුතු ණය මුදල  "
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
            <Description
              content={`Rs. ${totalLoanAmount.toFixed(2)} `}
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
          </div>

          {/* වාරික ගණන */}
          <div>
            <Description
              content="වාරික ගණන"
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
            <Description
              content={String(getInstallments.length)}
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
          </div>

          {/* ආරම්භක දිනය  */}
          <div>
            <Description
              content="ආරම්භක දිනය "
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
            <Description
              content={loanStartDate}
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
          </div>

          {/* අවසාන දිනය */}
          <div>
            <Description
              content="අවසාන දිනය"
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
            <Description
              content={loanEndDate}
              fontSize="text-h8"
              fontWeight="font-bold"
              center
              fontColor="text-gray-600"
            />
          </div>
        </div>
      </div>

      <Table
        dataSource={getInstallments}
        columns={[
          {
            title: "වාරිකය",
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            render: (_: any, __: any, index: number) => index + 1,
          },
          {
            title: "වාරික මුදල (Rs.)",
            dataIndex: "currentInstallmentAmount",
            key: "currentInstallmentAmount",
            align: "center" as const,
            render: (amount: number) => `${amount.toFixed(2)}`,
          },
          {
            title: "ගෙවිය යුතු දිනය",
            dataIndex: "dueDate",
            key: "dueDate",
            align: "center" as const,
            render: (date: string) => formatDate(date),
          },
          {
            title: "ගෙවන ලද දිනය",
            dataIndex: "payments",
            key: "paymentDate",
            align: "center" as const,
            render: (payments: any[]) => {
              return (
                <div>
                  {payments.map((payment, index) => {
                    if (
                      payment.paymentStatus === "PAID" &&
                      payment.paymentDate === null
                    ) {
                      return <div key={index}>-</div>;
                    } else {
                      return (
                        <div key={index}>{formatDate(payment.paymentDate)}</div>
                      );
                    }
                  })}
                </div>
              );
            },
          },
          {
            title: "වාරිකය සම්පූර්ණ කළ දිනය",
            dataIndex: "paidTime",
            key: "paidTime",
            align: "center" as const,
            render: (date: string, record: Installments) =>
              record.paymentStatus === "PAID" && date === null
                ? "-"
                : formatDate(date),
          },
          {
            title: "ගෙවු මුදල (Rs.)",
            dataIndex: "payments",
            key: "payments",
            align: "center" as const,
            render: (payments: any[]) => {
              return (
                <div>
                  {payments.map((payment, index) => {
                    if (
                      payment.paidAmount === 0 &&
                      payment.paymentStatus === "PAID"
                    ) {
                      return <div key={index}>-</div>;
                    } else if (payment.paidAmount === 0) {
                      return "";
                    } else {
                      return (
                        <div key={index}>{payment.paidAmount.toFixed(2)}</div>
                      );
                    }
                  })}
                </div>
              );
            },
          },
          {
            title: "සම්පූර්ණ වූ ඉදිරි වාරික (Rs.)",
            dataIndex: "paidAmount",
            key: "paidAmount",
            align: "center" as const,
            render: (amount: number) =>
              amount === 0 ? "" : `${amount.toFixed(2)}`,
          },
          {
            title: "ඉදිරියට ගෙනගිය ශේෂය (Rs.)",
            dataIndex: "balanceCarriedForward",
            key: "balanceCarriedForward",
            align: "center" as const,
            render: (amount: number, record: Installments) =>
              amount === 0 && record.paymentStatus === "PAID"
                ? "-"
                : record.fullPaymentAmount === 0 &&
                    record.paymentStatus !== "PAID"
                  ? ""
                  : amount !== 0
                    ? `${amount.toFixed(2)}`
                    : " ",
          },
          {
            title: "පොලී රහිත ඉතිරි ණය මුදල (Rs.)",
            dataIndex: "pureRemainingLoanAmount",
            key: "pureRemainingLoanAmount",
            align: "center" as const,
            render: (amount: number, record: Installments, index: number) => {
              const isLastSchedule =
                index === getInstallments.length - 1 &&
                record.paymentStatus === "PAID";
              return isLastSchedule
                ? "0.00"
                : amount === 0
                  ? ""
                  : `${amount.toFixed(2)}`;
            },
          },
          {
            title: "පොලී සහිත ඉතිරි ණය මුදල (Rs.)",
            dataIndex: "remainingAmount",
            key: "remainingAmount",
            align: "center" as const,
            render: (amount: number, record: Installments, index: number) => {
              const isLastSchedule =
                index === getInstallments.length - 1 &&
                record.paymentStatus === "PAID";
              return isLastSchedule
                ? "0.00"
                : amount === 0
                  ? ""
                  : `${amount.toFixed(2)}`;
            },
          },
          {
            title: "ප්‍රමාද වූ ගෙවීම් පොලී (Rs.)",
            dataIndex: "latePaymentInterest",
            key: "latePaymentInterest",
            align: "center" as const,
            render: (amount: number, record: Installments) =>
              amount === 0 && record.paymentStatus === "PAID"
                ? "-"
                : amount !== 0
                  ? `${amount.toFixed(2)}`
                  : "",
          },
          {
            title: "ගෙවීම් තත්වය",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            align: "center" as const,
            render: (status: string) => (
              <span className="flex justify-center">
                {status === "PAID" && <div className="-mt-2 text-h7">Paid</div>}
              </span>
            ),
          },
        ]}
        rowKey="scheduleId"
        pagination={false}
        bordered
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default ScheduleListModal;
