import React, { useEffect, useState } from "react";
import { Main, PrimaryTitle } from "../../common";
import C_AnaliticsCardsSection from "./sections/C_AnaliticsCardsSection";
import A_LineChartSection from "./sections/A_LineChartSection";
import { loanSummeryCollectorId } from "../../../api/loan";
import { Data, defaultData } from "./DefaultData";
import {
  getTotalLoanANdpaymentCurrentDaily,
  getTotalLoanANdpaymentCurrentMonthly,
  getTotalLoanANdpaymentCurrentWeekly,
} from "../../../api/summarry";
import C_CollectorPerfoTable from "../../Tables/collector/C_CollectorPerfoTable";
import C_ChartsSection from "./sections/C_ChartsSection";

interface LegendData {
  label: string;
  value: string;
  color: string;
}
type PeriodType = "year" | "month" | "week" | "day";

const C_AnaliticsOverview: React.FC = () => {
  const [data, setData] = useState<Data>(defaultData);
  const [_legendDataBar, setLegendDataBar] = useState<LegendData[]>([]); // Correct state function
  const [timeframeDay, setTimeframeDay] = useState("12 months");
  const [timeframeWeek, setTimeframeWeek] = useState("12 months");
  const [timeframeMonth, setTimeframeMonth] = useState("12 months");
  const [selectedDateDay, setSelectedDateDay] = useState<string>("");
  const [selectedDateWeek, setSelectedDateWeek] = useState<string>("");
  const [selectedDateMonth, setSelectedDateMonth] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      const collectorId = Number(localStorage.getItem("userId"));
      const token = localStorage.getItem("token") || "";

      try {
        const response = await loanSummeryCollectorId(collectorId, token);
        setData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const getCurrentTotalLoans = async (
    freq: PeriodType,
    setLoanAmounts: React.Dispatch<
      React.SetStateAction<{
        totalLoanAmounts: number[];
        totalPaymentsAmounts: number[];
      }>
    >,
    fetchFunction: (
      token: string,
      userId: number,
      frequency: string,
      date: string
    ) => Promise<{
      totalLoanAmounts: number[];
      totalPaymentsAmounts: number[];
    }>,
    date: string
  ) => {
    const userId = Number(localStorage.getItem("userId"));
    const token = localStorage.getItem("token") || "";

    try {
      const response = await fetchFunction(token, userId, freq, date);
      setLoanAmounts(response);
    } catch (error) {
      console.error(error);
    }
  };
  const [getYearTotalLoanAmountsDaily, setGetYearTotalLoanAmountsDaily] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });
  const [getYearTotalLoanAmountsWeekly, setGetYearTotalLoanAmountsWeekly] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });
  const [getYearTotalLoanAmountsMonthly, setGetYearTotalLoanAmountsMonthly] =
    useState<{ totalLoanAmounts: number[]; totalPaymentsAmounts: number[] }>({
      totalLoanAmounts: [],
      totalPaymentsAmounts: [],
    });

  useEffect(() => {
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsDaily,
      getTotalLoanANdpaymentCurrentDaily,
      selectedDateDay
    );
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsWeekly,
      getTotalLoanANdpaymentCurrentWeekly,
      selectedDateWeek
    );
    getCurrentTotalLoans(
      "year",
      setGetYearTotalLoanAmountsMonthly,
      getTotalLoanANdpaymentCurrentMonthly,
      selectedDateMonth
    );
  }, [selectedDateDay, selectedDateWeek, selectedDateMonth]);

  const setTimeframeForDaily = async (freq: string) => {
    setTimeframeDay(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsDaily,
      getTotalLoanANdpaymentCurrentDaily,
      selectedDateDay
    );
  };

  const setTimeframeForWeekly = async (freq: string) => {
    setTimeframeWeek(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsWeekly,
      getTotalLoanANdpaymentCurrentWeekly,
      selectedDateWeek
    );
  };

  const setTimeframeForMonthly = async (freq: string) => {
    setTimeframeMonth(freq);
    const type = convertToPeriodType(freq);
    getCurrentTotalLoans(
      type,
      setGetYearTotalLoanAmountsMonthly,
      getTotalLoanANdpaymentCurrentMonthly,
      selectedDateMonth
    );
  };

  const convertToPeriodType = (freq: string): PeriodType => {
    switch (freq) {
      case "12 months":
        return "year";
      case "30 days":
        return "month";
      case "7 days":
        return "week";
      case "24 hours":
        return "day";
      default:
        throw new Error("Invalid frequency");
    }
  };
  const getLabels = (timeframe: string) => {
    switch (timeframe) {
      case "30 days":
        return Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);
      case "7 days":
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      case "24 hours":
        return Array.from({ length: 24 }, (_, i) => `${i}:00`);
      default:
        return [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
    }
  };

  const doughnutData = {
    datasets: [
      {
        data: data.paymentPlanSummary.map((item) => item.percentage),
        backgroundColor: ["#506F6B", "#5B9D8D", "#8EBBB1"],
        borderWidth: 0,
      },
    ],
    labels: data.paymentPlanSummary.map((item) => item.repaymentFrequency),
  };
  const totalDue = data.paymentPlanSummary
    .reduce((sum, item) => sum + item.totalRemainingAmount, 0)
    .toFixed(2);
  const legendData = data.paymentPlanSummary.map((item) => ({
    label: item.repaymentFrequency,
    value: `Rs. ${item.totalRemainingAmount.toFixed(2)}`,
    color:
      item.repaymentFrequency === "DAILY"
        ? "bg-[#506F6B]"
        : item.repaymentFrequency === "WEEKLY"
          ? "bg-[#5B9D8D]"
          : "bg-[#8EBBB1]",
  }));

  const barData = {
    labels: ["DAILY", "WEEKLY", "MONTHLY"],
    datasets: [
      {
        label: "Approved",
        data: [
          data.loanStatusSummary.DAILY.approvedCount,
          data.loanStatusSummary.WEEKLY.approvedCount,
          data.loanStatusSummary.MONTHLY.approvedCount,
        ],
        backgroundColor: "#506F6B",
      },
      {
        label: "Complete",
        data: [
          data.loanStatusSummary.DAILY.completeCount,
          data.loanStatusSummary.WEEKLY.completeCount,
          data.loanStatusSummary.MONTHLY.completeCount,
        ],
        backgroundColor: "#5B9D8D",
      },
      {
        label: "Pending",
        data: [
          data.loanStatusSummary.DAILY.pendingCount,
          data.loanStatusSummary.WEEKLY.pendingCount,
          data.loanStatusSummary.MONTHLY.pendingCount,
        ],
        backgroundColor: "#8EBBB1",
      },
      {
        label: "Rejected",
        data: [
          data.loanStatusSummary.DAILY.rejectedCount,
          data.loanStatusSummary.WEEKLY.rejectedCount,
          data.loanStatusSummary.MONTHLY.rejectedCount,
        ],
        backgroundColor: "#9AACAA",
      },
    ],
  };

  const updatedLegendData = [
    {
      label: "Approved",
      value: String(
        data.loanStatusSummary.DAILY.approvedCount +
          data.loanStatusSummary.WEEKLY.approvedCount +
          data.loanStatusSummary.MONTHLY.approvedCount
      ),
      color: "bg-[#506F6B]",
    },
    {
      label: "Pending",
      value: String(
        data.loanStatusSummary.DAILY.pendingCount +
          data.loanStatusSummary.WEEKLY.pendingCount +
          data.loanStatusSummary.MONTHLY.pendingCount
      ),
      color: "bg-[#5B9D8D]",
    },
    {
      label: "Complete",
      value: String(
        data.loanStatusSummary.DAILY.completeCount +
          data.loanStatusSummary.WEEKLY.completeCount +
          data.loanStatusSummary.MONTHLY.completeCount
      ),
      color: "bg-[#8EBBB1]",
    },
    {
      label: "Rejected",
      value: String(
        data.loanStatusSummary.DAILY.rejectedCount +
          data.loanStatusSummary.WEEKLY.rejectedCount +
          data.loanStatusSummary.MONTHLY.rejectedCount
      ),
      color: "bg-[#9AACAA]",
    },
  ];

  useEffect(() => {
    setLegendDataBar(updatedLegendData);
  }, []);

  const lineDataToday = (timeframeDay: string) => ({
    labels: getLabels(timeframeDay),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsDaily.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsDaily.totalPaymentsAmounts,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineDataWeek = (timeframeWeek: string) => ({
    labels: getLabels(timeframeWeek),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsWeekly.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsWeekly.totalPaymentsAmounts,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineDataMonth = (timeframeMonth: string) => ({
    labels: getLabels(timeframeMonth),
    datasets: [
      {
        label: "Disbursed Amount",
        data: getYearTotalLoanAmountsMonthly.totalLoanAmounts,
        borderColor: "#2d6a4f",
        backgroundColor: "rgba(45, 106, 79, 0.5)",
        fill: true,
      },
      {
        label: "Revenue",
        data: getYearTotalLoanAmountsMonthly.totalPaymentsAmounts,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
      },
    ],
  });

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Transactions",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        beginAtZero: true,
        position: "left" as const,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <Main>
      <C_AnaliticsCardsSection loanSummary={data.loanSummary} />
      <div className="flex flex-wrap justify-center gap-2 p-2">
        <C_ChartsSection
          doughnutData={doughnutData}
          barData={barData}
          legendData={legendData}
          legendDataBar={updatedLegendData}
          totalDue={totalDue}
        />

        <div className="p-2 my-4  rounded-lg shadow-md w-[370px] bg-primaryColor-50">
          <div className="flex justify-center mb-4 border-b-2 border-primaryColor-100">
            <PrimaryTitle
              fontColor="text-black"
              fontSize="text-2xl"
              text="Customers Perfomanace"
            />
          </div>
          <C_CollectorPerfoTable />
        </div>
      </div>

      {/*  Daily */}
      <A_LineChartSection
        title="Transactions-Daily"
        timeframe={timeframeDay}
        lineData={lineDataToday}
        setTimeframe={setTimeframeForDaily}
        options={lineOptions}
        setSelectedDate={setSelectedDateDay}
      />

      {/*  Weekly */}
      <A_LineChartSection
        title="Transactions-Weekly"
        timeframe={timeframeWeek}
        lineData={lineDataWeek}
        setTimeframe={setTimeframeForWeekly}
        options={lineOptions}
        setSelectedDate={setSelectedDateWeek}
      />

      {/*  Monthly */}
      <A_LineChartSection
        title="Transactions-Monthly"
        timeframe={timeframeMonth}
        lineData={lineDataMonth}
        setTimeframe={setTimeframeForMonthly}
        options={lineOptions}
        setSelectedDate={setSelectedDateMonth}
      />
    </Main>
  );
};

export default C_AnaliticsOverview;
