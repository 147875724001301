import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { getAllshedulesForCalander } from "../../../../api/calander";
import { MdOutlineNavigateNext } from "react-icons/md";
import CalanderOverviewModal from "../../../common/modals/CalanderOverviewModal";
import { Description } from "../../../common";

interface DayProps {
  selectedDate: Date;
  selectedCustomer: CustomerLoan[];
  selectedcollectorId: number | null;
  selectedManagerId: number | null;
}

interface Schedule {
  id: number;
  date: string; // Include the date field in the schedule object
  customerName: string;
  customerAddress: string;
  customerNic: string;
  dueDate: string;
  dueAmount: number;
  customerPhoneNumber: string;
  customerWhatsAppNumber: string;
  numberOfInstallments: number;
  loanAmount: number;
  loanId: number;
  collectorId: number;
  managerId: number;
  paymentStatus: string;
}

interface CustomerLoan {
  customerName: string;
  loanId: number;
}

const Day: React.FC<DayProps> = ({
  selectedDate,
  selectedCustomer,
  selectedcollectorId,
  selectedManagerId,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date()); //save date come from JSCalender
  const [allSchedules, setAllSchedules] = useState<Record<string, Schedule[]>>(
    {}
  ); //save all shedules
  const [schedules, setSchedules] = useState<Schedule[]>([]); //save shedules for selected date

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [filteredSchedules, setFilteredSchedules] = useState<
    Record<string, Schedule[]>
  >({}); //save filtered customers

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);

      try {
        const response = await getAllshedulesForCalander(userId, token);

        let filteredResponse = response;

        if (selectedManagerId !== null) {
          filteredResponse = response.filter(
            (schedule: Schedule) => schedule.managerId === selectedManagerId
          );
        }

        if (selectedcollectorId !== null) {
          filteredResponse = filteredResponse.filter(
            (schedule: Schedule) => schedule.collectorId === selectedcollectorId
          );
        }

        const formattedSchedules = mapSchedulesToDates(filteredResponse);
        setAllSchedules(formattedSchedules);
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };

    fetchData();
  }, [selectedcollectorId, selectedManagerId]);

  useEffect(() => {
    const dayKey = format(currentDate, "yyyy-MM-dd");
    if (selectedCustomer.length > 0) {
      // Filter schedules based on selected customers' loanIds
      const filtered = Object.entries(allSchedules).reduce(
        (acc, [dateKey, scheduleArray]) => {
          const matchingSchedules = scheduleArray.filter((schedule) =>
            selectedCustomer.some(
              (customer) => customer.loanId === schedule.loanId
            )
          );
          if (matchingSchedules.length > 0) {
            acc[dateKey] = matchingSchedules;
          }
          return acc;
        },
        {} as Record<string, Schedule[]>
      );

      setFilteredSchedules(filtered);
      //set shedules for current date
      setSchedules(filtered[dayKey] || []);
    } else {
      // No selected customer, use original schedules
      setFilteredSchedules(allSchedules);
      //set shedules for current date
      setSchedules(allSchedules[dayKey] || []);
    }
  }, [selectedCustomer, allSchedules]);

  //selected shedules for current date
  useEffect(() => {
    setSchedules(getSchedulesForDay(currentDate));
  }, [currentDate]);

  // Function to go to the previous day
  const goToPreviousDay = () => {
    const previousDay = subDays(currentDate, 1);
    setCurrentDate(previousDay);
    //  onDateChange(previousDay);
  };

  // Function to go to the next day
  const goToNextDay = () => {
    const nextDay = addDays(currentDate, 1);
    setCurrentDate(nextDay);
    // onDateChange(nextDay);
  };

  // Function to get schedules for the current day
  const getSchedulesForDay = (date: Date) => {
    const dayKey = format(date, "yyyy-MM-dd");
    return filteredSchedules[dayKey] || [];
  };

  const mapSchedulesToDates = (
    schedules: Schedule[]
  ): Record<string, Schedule[]> => {
    return schedules.reduce(
      (acc: Record<string, Schedule[]>, schedule: Schedule) => {
        const dateKey = format(new Date(schedule.dueDate), "yyyy-MM-dd");
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push({
          id: schedule.id,
          customerName: schedule.customerName,
          customerAddress: schedule.customerAddress,
          customerPhoneNumber: schedule.customerPhoneNumber,
          dueDate: schedule.dueDate,
          customerNic: schedule.customerNic,
          dueAmount: schedule.dueAmount,
          customerWhatsAppNumber: schedule.customerWhatsAppNumber,
          numberOfInstallments: schedule.numberOfInstallments,
          loanId: schedule.loanId,
          loanAmount: schedule.loanAmount,
          collectorId: schedule.collectorId,
          managerId: schedule.managerId,
          date: dateKey,
          paymentStatus: schedule.paymentStatus,
        });
        return acc;
      },
      {}
    );
  };

  const handleScheduleClick = (schedule: Schedule) => {
    setSelectedSchedule(schedule);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedSchedule(null);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between mb-1">
        <h2 className="text-lg font-bold lg:text-2xl text-primaryColor-900">
          {format(currentDate, "MMMM d, yyyy")}
        </h2>

        <div className="flex flex-row text-h5 lg:text-h3">
          <button
            onClick={goToPreviousDay}
            className="px-4 rounded text-primaryColor-900"
          >
            <MdOutlineNavigateNext className="transform scale-x-[-1]" />
          </button>
          <button
            onClick={goToNextDay}
            className="px-4 rounded text-primaryColor-900"
          >
            <MdOutlineNavigateNext />
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <div className="text-lg font-semibold text-primaryColor-600">
          {format(currentDate, "EEEE")}
        </div>
        <div className="flex flex-wrap items-center justify-center w-full gap-2 mt-4">
          {schedules.length > 0 ? (
            schedules.map((schedule) => (
              <div
                key={schedule.id}
                className={`w-48 p-2 mb-2 break-words rounded-lg shadow-md ${schedule.paymentStatus === "OVERDUE" ? "text-red-600" : "text-primaryColor-900"} ${schedule.paymentStatus === "OVERDUE" ? "bg-red-100" : "bg-primaryColor-100"}`}
                onClick={() => handleScheduleClick(schedule)}
              >
                <div className={`font-bold`}>{schedule.customerName}</div>
                <div>{schedule.customerAddress}</div>
                <div>{schedule.customerPhoneNumber}</div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center w-full mt-5 text-center ">
              <Description content={"No events scheduled for this day."} />
            </div>
          )}
        </div>
      </div>
      <CalanderOverviewModal
        visible={isModalOpen}
        onClose={handleModalClose}
        content={selectedSchedule}
        closeIcon
      />
    </div>
  );
};

export default Day;
