import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

// get total-loan-and-payments-current-year
export const getTotalLoanANdpaymentCurrentDaily = async (
  token: string,
  userId: number,
  frequency: string,
  date: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/daily/${userId}/${frequency}/${date}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get total-loan-and-payments-current-month
export const getTotalLoanANdpaymentCurrentMonthly = async (
  token: string,
  userId: number,
  frequency: string,
  date: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/monthly/${userId}/${frequency}/${date}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get total-loan-and-payments-current-week
export const getTotalLoanANdpaymentCurrentWeekly = async (
  token: string,
  userId: number,
  frequency: string,
  date: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/weekly/${userId}/${frequency}/${date}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get profit
export const getProfits = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/schedules/api/profit?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get Total Paid Amounts
export const getTotalPaidAmounts = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/payments/by-date-range?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
