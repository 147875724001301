import React, { useEffect, useState } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import "./Calendar.css";

interface ContentItem {
  scheduleCount: number;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  currentInstallmentAmount: number;
  dueDate: string;
  collectorId: number;
  customerId: number;
  paymentStatus: string;
}

interface CalendarProps {
  contentItems: ContentItem[];
  onDateChange: (date: Date) => void;
  selectedJsCalanderDate: Date;
}

const Calendar: React.FC<CalendarProps> = ({
  contentItems,
  onDateChange,
  selectedJsCalanderDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedCell, setSelectedCell] = useState<string | null>(null);
  const [, setSelectedContent] = useState<ContentItem[]>([]);

  useEffect(() => {
    setSelectedDate(selectedJsCalanderDate);
    const dateString = selectedJsCalanderDate.toISOString();

    const isSmallScreen = window.matchMedia("(max-width: 639px)").matches;
    if (!isSmallScreen) {
      handleCellClick(dateString, selectedJsCalanderDate);
    }
  }, [selectedJsCalanderDate]);

  const handleCellClick = (date: string, _selectedDate: Date) => {
    if (!date) return;

    const formattedDate = new Date(date);
    onDateChange(formattedDate);

    if (selectedCell === date) {
      setSelectedCell(null);
      setSelectedContent([]);
    } else {
      setSelectedCell(date);
      const clickedContent = contentItems.filter(
        (item) => item.dueDate === date
      );
      setSelectedContent(clickedContent);
    }
  };

  const countNames = (item: ContentItem): number => {
    let count = 1;
    for (let i = 2; i <= 10; i++) {
      if ((item as any)[`name${i}`]) {
        count++;
      }
    }
    return count;
  };

  const updatedContentItems = contentItems.map((item) => ({
    ...item,
    content: `${countNames(item)}`,
  }));

  const startOfMonthDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  );
  const startDayOfWeek = (startOfMonthDate.getDay() + 6) % 7;
  const daysInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();

  const datesOfMonth = Array.from(
    { length: Math.ceil((daysInMonth + startDayOfWeek) / 7) * 7 },
    (_, i) => {
      const date = i - startDayOfWeek + 1;
      return date > 0 && date <= daysInMonth ? date : "";
    }
  );

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handlePrevMonth = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
  };

  const handleNextMonth = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
  };

  const monthName = selectedDate.toLocaleString("default", { month: "long" });
  const year = selectedDate.getFullYear();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <div className="p-5 ">
      <div className="">
        <div className="flex items-center justify-between px-2 mb-2 ">
          <h2 className="text-lg font-bold lg:text-2xl text-primaryColor-900">
            {monthName} {year}
          </h2>
          <div className="flex flex-row text-h5 lg:text-h3">
            <button
              onClick={handlePrevMonth}
              className="px-4 rounded text-primaryColor-900"
            >
              <MdOutlineNavigateNext className="transform scale-x-[-1]" />
            </button>
            <button
              onClick={handleNextMonth}
              className="px-4 rounded text-primaryColor-900"
            >
              <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
        <table className="sticky top-0 z-30 w-full max-h-screen min-w-full bg-white border-primaryColor-200">
          <thead className="bg-white ">
            <tr>
              {daysOfWeek.map((day, index) => (
                <th
                  key={index}
                  className="w-4 px-2 font-bold uppercase lg:w-16 text-primaryColor-200 text-h9 lg:text-h7"
                  style={{ border: "none" }}
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
        </table>
        <table className="w-full max-h-screen min-w-full border border-collapse table-fixed border-primaryColor-200">
          <tbody>
            {Array.from({ length: Math.ceil(datesOfMonth.length / 7) }).map(
              (_, rowIndex) => (
                <tr key={rowIndex} className="h-full">
                  {daysOfWeek.map((_, colIndex) => {
                    const date = datesOfMonth[rowIndex * 7 + colIndex];
                    const dateString = date
                      ? `${selectedDate.getFullYear()}-${String(
                          selectedDate.getMonth() + 1
                        ).padStart(2, "0")}-${String(date).padStart(2, "0")}`
                      : "";
                    const isSelected = selectedCell === dateString;

                    const contentForDate = updatedContentItems.filter(
                      (item) => item.dueDate === dateString
                    );

                    const cellDate = new Date(dateString);
                    const isPastDue =
                      cellDate < today &&
                      (today.getTime() - cellDate.getTime()) /
                        (1000 * 60 * 60 * 24) >
                        2;

                    return (
                      <td
                        key={colIndex}
                        className={`border border-primaryColor-200 xl:h-40 cursor-pointer relative font-bold max-h-[80%] p-[14px] lg:p-[34px] max-p-12 text-center text-h6  `}
                        onClick={() =>
                          handleCellClick(dateString, selectedDate)
                        }
                      >
                        {date}
                        {contentForDate.length > 0 &&
                          contentForDate.map((_item, index) => (
                            <div
                              key={index}
                              className={`absolute inset-0 duration-300  ${isPastDue ? (isSelected ? "bg-red-500 text-white" : "bg-red-300 text-white") : isSelected ? "bg-primaryColor-500 text-white" : "bg-primaryColor-200 text-white"} h-5 w-5 m-1 lg:m-0 lg:h-[30px] rounded-md lg:w-[90%] p-1 lg:mx-2 lg:mt-1 text-center font-semibold flex items-center justify-center z-10`}
                            >
                              <div className="flex flex-col items-center ">
                                <p className="font-semibold text-center text-h9">
                                  {contentForDate.length}
                                  <span className="hidden lg:inline-block">
                                    &nbsp;Customers
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                      </td>
                    );
                  })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
