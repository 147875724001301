/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

interface ButtonProps {
  text: string;
  bgColor?: string;
  border?: string;
  fontSize?: string;
  fontWeight?: "font-thin" | "font-normal" | "font-semibold" | "font-bold";
  width?: string;
  onClick?: () => void;
  id?: string;
  name?: string;
  icon?: React.ReactNode;
  iconPosition?: "before" | "after";
  variant?: "primary" | "secondary";
  isLoading?: boolean;
  disabled?: boolean;
  fontColor?: string;
  activeBg?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    text,
    bgColor = `bg-primaryColor-500 `,
    border,
    fontSize = "text-h8 md:text-h7",
    fontWeight = "font-normal",
    width = "w-44",
    onClick,
    id,
    name,
    icon,
    iconPosition = "before",
    variant = "primary",
    isLoading,
    disabled,
    fontColor = "text-white",
    activeBg = true,
  } = props;

  const fontFamily = "font-poppins";

  const buttonClasses =
    variant === "primary"
      ? `${
          disabled
            ? "bg-primaryColor-100 border-primaryColor-100 cursor-not-allowed"
            : `${bgColor} ${border} ${activeBg ? "active:bg-primaryColor-600" : ""} border-primaryColor-500`
        } ${fontColor} border-2`
      : `${
          disabled
            ? "border-2 border-primaryColor-100 cursor-not-allowed text-primaryColor-300"
            : `border-2 border-primaryColor-500 bg-transparent text-primaryColor-500   ${activeBg ? "active:bg-primaryColor-50" : ""}`
        }`;

  return (
    <div>
      <button
        ref={ref}
        id={id}
        disabled={disabled}
        name={name}
        onClick={onClick}
        className={`${buttonClasses} py-[6px] ${fontSize} ${fontWeight} ${fontFamily} ${width} rounded-lg flex items-center justify-center`}
      >
        {icon && iconPosition === "before" && (
          <span className="mr-2">{icon}</span>
        )}
        {text}
        {icon && iconPosition === "after" && (
          <span className="ml-2">{icon}</span>
        )}
        {isLoading && (
          <div
            role="status"
            className="inline-block ml-2 text-primary-purple h-4 w-4 3xl:h-5 3xl:w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          ></div>
        )}
      </button>
    </div>
  );
});

Button.displayName = "Button";

// Define PropTypes
Button.propTypes = {
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  border: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOf([
    "font-thin",
    "font-normal",
    "font-semibold",
    "font-bold",
  ]),
  width: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(["before", "after"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
