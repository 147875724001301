import React, { useEffect, useState } from "react";
import { Chart } from "../../../common/";
import { loanSummeryUserId } from "../../../../api/loan";

interface PaymentPlanSummary {
  repaymentFrequency: "DAILY" | "WEEKLY" | "MONTHLY";
  count: number;
  totalRemainingAmount: number;
  percentage: number;
}

interface LegendData {
  label: string;
  value: string;
  color: string;
}

interface BarData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
}

const A_ChartsSection = () => {
  const [doughnutData, setDoughnutData] = useState({
    datasets: [
      {
        data: [],
        backgroundColor: ["#506F6B", "#5B9D8D", "#8EBBB1"],
        borderWidth: 1,
      },
    ],
    labels: [],
    totalDue: 0,
  });

  const [legendData, setLegendData] = useState<LegendData[]>([]);
  const [legendDataC, setCustomerLegendData] = useState<LegendData[]>([]);
  const [legendDoughnut, setLegendDoughnut] = useState<LegendData[]>([]);

  const [barData, setBarData] = useState<BarData>({
    labels: ["DAILY", "WEEKLY", "MONTHLY"],
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#506F6B",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#5B9D8D",
      },
      {
        label: "Complete",
        data: [],
        backgroundColor: "#8EBBB1",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#9AACAA",
      },
    ],
  });

  const [barDataC, setBarDataC] = useState<BarData>({
    labels: ["DAILY", "WEEKLY", "MONTHLY"],
    datasets: [
      {
        label: "Fully Paid",
        data: [],
        backgroundColor: "#506F6B",
      },
      {
        label: "Performing",
        data: [],
        backgroundColor: "#5B9D8D",
      },
      {
        label: "Overdue",
        data: [],
        backgroundColor: "#8EBBB1",
      },
      {
        label: "Legal Action",
        data: [],
        backgroundColor: "#9AACAA",
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const fetchedData = await loanSummeryUserId(token);

        setDoughnutData({
          datasets: [
            {
              data: fetchedData.data.paymentPlanSummary.map(
                (item: PaymentPlanSummary) => item.percentage
              ),
              backgroundColor: ["#506F6B", "#5B9D8D", "#8EBBB1"],
              borderWidth: 1,
            },
          ],
          labels: fetchedData.data.paymentPlanSummary.map(
            (item: PaymentPlanSummary) => item.repaymentFrequency
          ),
          totalDue: fetchedData.data.paymentPlanSummary.reduce(
            (sum: number, item: PaymentPlanSummary) =>
              sum + item.totalRemainingAmount,
            0
          ),
        });

        const updatedLegendData = [
          {
            label: "Approved",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.approvedCount +
                fetchedData.data.loanStatusSummary.WEEKLY.approvedCount +
                fetchedData.data.loanStatusSummary.MONTHLY.approvedCount
            ),
            color: "bg-[#506F6B]",
          },
          {
            label: "Pending",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.pendingCount +
                fetchedData.data.loanStatusSummary.WEEKLY.pendingCount +
                fetchedData.data.loanStatusSummary.MONTHLY.pendingCount
            ),
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Complete",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.completeCount +
                fetchedData.data.loanStatusSummary.WEEKLY.completeCount +
                fetchedData.data.loanStatusSummary.MONTHLY.completeCount
            ),
            color: "bg-[#8EBBB1]",
          },
          {
            label: "Rejected",
            value: String(
              fetchedData.data.loanStatusSummary.DAILY.rejectedCount +
                fetchedData.data.loanStatusSummary.WEEKLY.rejectedCount +
                fetchedData.data.loanStatusSummary.MONTHLY.rejectedCount
            ),
            color: "bg-[#9AACAA]",
          },
        ];

        setLegendData(updatedLegendData);

        const legendDoughnut = [
          {
            label: "Daily",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "DAILY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#506F6B]",
          },
          {
            label: "Weekly",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "WEEKLY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Monthly",
            value: `Rs. ${
              fetchedData.data.paymentPlanSummary.find(
                (item: { repaymentFrequency: string }) =>
                  item.repaymentFrequency === "MONTHLY"
              )?.totalRemainingAmount || 0
            }`,
            color: "bg-[#8EBBB1]",
          },
        ];

        setLegendDoughnut(legendDoughnut);

        const legendDataC = [
          {
            label: "Fully Paid",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.fullyPaidCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .fullyPaidCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .fullyPaidCount
            ),
            color: "bg-[#506F6B]",
          },
          {
            label: "Performing",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.performingCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .performingCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .performingCount
            ),
            color: "bg-[#5B9D8D]",
          },
          {
            label: "Overdue",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.overdueCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY.overdueCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY.overdueCount
            ),
            color: "bg-[#8EBBB1]",
          },
          {
            label: "Legal Action",
            value: String(
              fetchedData.data.customerLoanStatusSummary.DAILY.defaultCount +
                fetchedData.data.customerLoanStatusSummary.WEEKLY.defaultCount +
                fetchedData.data.customerLoanStatusSummary.MONTHLY.defaultCount
            ),
            color: "bg-[#9AACAA]",
          },
        ];

        setCustomerLegendData(legendDataC);

        setBarData({
          labels: ["DAILY", "WEEKLY", "MONTHLY"],
          datasets: [
            {
              label: "Approved",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.approvedCount,
                fetchedData.data.loanStatusSummary.WEEKLY.approvedCount,
                fetchedData.data.loanStatusSummary.MONTHLY.approvedCount,
              ],
              backgroundColor: "#506F6B",
            },
            {
              label: "Pending",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.pendingCount,
                fetchedData.data.loanStatusSummary.WEEKLY.pendingCount,
                fetchedData.data.loanStatusSummary.MONTHLY.pendingCount,
              ],
              backgroundColor: "#5B9D8D",
            },
            {
              label: "Complete",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.completeCount,
                fetchedData.data.loanStatusSummary.WEEKLY.completeCount,
                fetchedData.data.loanStatusSummary.MONTHLY.completeCount,
              ],
              backgroundColor: "#8EBBB1",
            },
            {
              label: "Rejected",
              data: [
                fetchedData.data.loanStatusSummary.DAILY.rejectedCount,
                fetchedData.data.loanStatusSummary.WEEKLY.rejectedCount,
                fetchedData.data.loanStatusSummary.MONTHLY.rejectedCount,
              ],
              backgroundColor: "#9AACAA",
            },
          ],
        });

        setBarDataC({
          labels: ["DAILY", "WEEKLY", "MONTHLY"],
          datasets: [
            {
              label: "Fully Paid",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.fullyPaidCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .fullyPaidCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .fullyPaidCount,
              ],
              backgroundColor: "#506F6B",
            },
            {
              label: "Performing",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY
                  .performingCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY
                  .performingCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY
                  .performingCount,
              ],
              backgroundColor: "#5B9D8D",
            },
            {
              label: "Overdue",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.overdueCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY.overdueCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY.overdueCount,
              ],
              backgroundColor: "#8EBBB1",
            },
            {
              label: "Legal Action",
              data: [
                fetchedData.data.customerLoanStatusSummary.DAILY.defaultCount,
                fetchedData.data.customerLoanStatusSummary.WEEKLY.defaultCount,
                fetchedData.data.customerLoanStatusSummary.MONTHLY.defaultCount,
              ],
              backgroundColor: "#9AACAA",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-wrap justify-center gap-4 p-4">
      <Chart
        type="doughnut"
        title="Payment Plan"
        data={doughnutData}
        options={{
          plugins: {
            legend: { display: false },
            centerText: {},
          },
          cutout: "70%",
        }}
        legendData={legendDoughnut}
        centerTextOptions={{
          text: "Total Due",
          amount: `Rs. ${doughnutData.totalDue?.toFixed(2) || 0}`,
        }}
      />
      <Chart
        type="bar"
        title="Application Overview"
        data={barData}
        options={{
          responsive: true,
          scales: {
            x: { beginAtZero: true, stacked: true, grid: { display: false } },
            y: { beginAtZero: true, stacked: true, grid: { display: true } },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        legendData={legendData}
      />

      <Chart
        type="bar"
        title="Customer Overview"
        data={barDataC}
        options={{
          responsive: true,
          scales: {
            x: { beginAtZero: true, stacked: true, grid: { display: false } },
            y: { beginAtZero: true, stacked: true, grid: { display: true } },
          },
          plugins: {
            legend: {
              display: false, // Disable the legend
            },
          },
        }}
        legendData={legendDataC}
      />
    </div>
  );
};

export default A_ChartsSection;
