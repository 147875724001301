import React, { useEffect, useState } from "react";
import { getAllshedulesForCalander } from "../../../../api/calander";
import { Calendar } from "../../../common";

interface ScheduleItem {
  scheduleCount: number;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  currentInstallmentAmount: number;
  dueDate: string;
  collectorId: number;
  customerId: number;
  loanId: number;
  managerId: number;
  paymentStatus: string;
}

interface CustomerLoan {
  customerName: string;
  loanId: number;
}

interface MonthProps {
  onDateSelect: (date: Date) => void;
  selectedJsCalanderDate: Date;
  selectedCustomer: CustomerLoan[];
  selectedcollectorId: number | null;
  selectedManagerId: number | null;
}

const ScheduleCalenderPage: React.FC<MonthProps> = ({
  onDateSelect,
  selectedJsCalanderDate,
  selectedCustomer,
  selectedcollectorId,
  selectedManagerId,
}) => {
  const [contentItems, setContentItems] = useState<ScheduleItem[]>([]);
  //save filter data from customer name
  const [filteredContentItems, setFilteredContentItems] = useState<
    ScheduleItem[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);
      try {
        const response = await getAllshedulesForCalander(userId, token);

        if (Array.isArray(response)) {
          let filteredResponse = response;

          if (selectedManagerId !== null) {
            filteredResponse = response.filter(
              (schedule: ScheduleItem) =>
                schedule.managerId === selectedManagerId
            );
          }
          if (selectedcollectorId !== null) {
            filteredResponse = filteredResponse.filter(
              (schedule: ScheduleItem) =>
                schedule.collectorId === selectedcollectorId
            );
          }

          setContentItems(filteredResponse as ScheduleItem[]);
        } else {
          throw new Error("Unexpected response format");
        }

        // Set the data to contentItems state
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchData();
  }, [selectedcollectorId, selectedManagerId]);

  useEffect(() => {
    if (selectedCustomer.length > 0) {
      // Filter contentItems based on selectedCustomer's loanId
      const filteredItems = contentItems.filter((item) =>
        selectedCustomer.some(
          (customerLoan) => customerLoan.loanId === item.loanId
        )
      );
      setFilteredContentItems(filteredItems);
    } else {
      // No selected customer, use original contentItems
      setFilteredContentItems(contentItems);
    }
  }, [selectedCustomer, contentItems]);

  return (
    <div>
      <Calendar
        contentItems={filteredContentItems}
        onDateChange={onDateSelect}
        selectedJsCalanderDate={selectedJsCalanderDate}
      />
    </div>
  );
};

export default ScheduleCalenderPage;
